import { Input } from 'antd'
const { Search } = Input
const SearchBar = ({ dataSearchSite }: { dataSearchSite: string }) => {
  const onSearch = (value: string) => {
    window.location.href = `${dataSearchSite}dataset?q=${value}`
  }
  return (
    <div className="searchBar">
      <Search
        style={{
          fontSize: '12px',
        }}
        placeholder="Search datasets"
        onSearch={onSearch}
        enterButton
        data-testid="searchBar"
      />
    </div>
  )
}

export default SearchBar

import {
  createGeoServerCredentials,
  deleteGeoServerCredentials,
  getGeoServerCredentials,
} from '@api/geoServer'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IGeoCred } from '@/types/geoServer'
import { message } from 'antd'

export function useGetGeoCredentials() {
  return useQuery<IGeoCred>('geoCredential', getGeoServerCredentials)
}

export function useCreateGeoCredentials(displayMessage = true) {
  const queryClient = useQueryClient()
  return useMutation(createGeoServerCredentials, {
    onSuccess: (res) => {
      displayMessage &&
        message.success(res?.message || 'Data Provider credentials created!')
      return queryClient.invalidateQueries('geoCredential')
    },
  })
}

export function useDeleteGeoCredentials() {
  const queryClient = useQueryClient()
  return useMutation(deleteGeoServerCredentials, {
    onSuccess: (res) => {
      message.success(res?.message || 'Data Provider credentials deleted!')
      return queryClient.invalidateQueries('geoCredential')
    },
  })
}

import { notification } from 'antd'
import { IconType } from 'antd/es/notification'
import Lottie from 'react-lottie'
import notFound from '@src/styles/lottie/notfound.json'
import { ReactNode } from 'react'
import { apmMonitor } from '@utils/apm'
import { eventName, eventType } from '@constants/eventTracking'

const showNotification = (
  type: IconType,
  duration: number,
  style: NonNullable<unknown>,
  message: ReactNode
) => {
  return notification.error({
    type: type,
    duration: duration,
    style: style,
    message: message,
  })
}

export const showErrorNotification = (statusCode: number) => {
  const REGEX_400S = new RegExp('4\\d\\d')
  const REGEX_500S = new RegExp('5\\d\\d')

  if (REGEX_400S.exec(String(statusCode)) || statusCode === 0) {
    apmMonitor(
      () => null,
      eventName.fileDownloadError,
      eventType.displayError,
      {
        access: 'request error',
      }
    )
    showNotification(
      'error',
      0,
      { width: 500 },
      <div
        style={{
          fontSize: 14,
        }}
      >
        Your request was unsuccessful, please ensure you have:
        <ul>
          <li>
            Accepted any relevant
            <a href={'/account/agreements'} target={'_blank'}>
              {' '}
              agreements{' '}
            </a>
            for the dataset
          </li>

          <li>
            Some datasets require eligible users to confirm use is within the
            academic{' '}
            <a href="/account/details" target="_blank">
              {' '}
              sector{' '}
            </a>
          </li>

          <li>
            Some datasets require
            <a href={'https://aurin.org.au/resources/data/'} target={'_blank'}>
              {' '}
              access applications{' '}
            </a>
          </li>
        </ul>
      </div>
    )
  }

  if (REGEX_500S.exec(String(statusCode))) {
    showNotification(
      'error',
      0,
      {},
      <p style={{ fontSize: 14 }}>
        Server Error: Status Code 500
        <br></br>
        <br></br>
        There was an error downloading this data from the server.
        <br></br>
        <br></br>
        This could be for a number of reasons:
        <ul>
          <li>
            If this dataset is stored outside AURIN, there could be an issue
            with the external datasource. Following up with the organisation
            linked in the dataset description may be helpful.
          </li>
          <li>
            This could also be due to an error on AURIN's servers. If you're
            persistently seeing this error, please get in touch with{' '}
            <a href="https://aurin.org.au/contact-us/">AURIN support</a>.
          </li>
        </ul>
      </p>
    )
  }
}

export const Lottie404 = (downloadUrl: { downloadUrl: string }) => {
  apmMonitor(() => null, eventName.fileDownloadError, eventType.display404, {
    downloadUrl: downloadUrl,
  })

  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: notFound,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={400}
      width={400}
    />
  )
}

import EP from '@src/api/endpoints'
import client from '@src/api'

export const getSectors = () => {
  return client.get(EP.SECTORS).then((res) => res.data?.sectors)
}

export const getUserSectors = () => {
  return client.get(EP.USER_SECTORS).then((res) => res.data || [])
}

export const postUserSector = (sector) => {
  return client.post(EP.SECTOR_ONE(sector)).then((res) => res.data)
}

export const deleteUserSector = (sector) => {
  return client.delete(EP.SECTOR_ONE(sector)).then((res) => res.data)
}

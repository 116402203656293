import EP from '@src/api/endpoints'
import client from '@src/api'

export const patchMarketing = (value) => {
  return client
    .patch(EP.OPT_OUT, {
      marketing_opt_out: value,
    })
    .then((res) => res.data)
}

export const getMarketingStatus = () =>
  client.get(EP.OPT_OUT).then((res) => res.data)

import { Button, Col, Empty, message, Popconfirm, Row, Spin } from 'antd'
import DetailRenderer from '@containers/userManagement/detailRenderer'
import React, { useEffect, useState } from 'react'
import {
  useCreateGeoCredentials,
  useDeleteGeoCredentials,
  useGetGeoCredentials,
} from '@hooks/query/useGeoServer'
import { IGeoCred } from '@/types/geoServer'
import copy from 'copy-to-clipboard'
import {
  CloudServerOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import PopoverC from '@components/common/popover'
import { apmMonitor } from '@utils/apm'
import { eventName, eventType } from '@constants/eventTracking'
import PageTitle from '@components/common/pageTitle'

const HiddenField = ({ data }: { data: IGeoCred }) => {
  const [hidden, setHidden] = useState<boolean>(true)

  const {
    mutateAsync: deleteGeoCred,
    isLoading: loadingDeleteCreds,
  } = useDeleteGeoCredentials()

  const toggleHidden = () => {
    setHidden(!hidden)
  }

  const handleCredCopy = () => {
    copy(data.password)
    message.info('Credential copied to clipboard!')
  }

  function confirm() {
    return deleteGeoCred()
  }

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <p style={{ width: 200 }}>
          {hidden ? '*****************' : data.password}{' '}
        </p>

        <Button
          data-testid={'showBtn'}
          type={'default'}
          style={{ marginLeft: 10 }}
          onClick={toggleHidden}
        >
          {hidden ? 'Show' : 'Hide'}
        </Button>
        <Button
          data-testid={'copyBtn'}
          type={'primary'}
          style={{ marginLeft: 10 }}
          onClick={() =>
            apmMonitor(handleCredCopy, 'copyGeoCreds', 'buttonClick')
          }
          icon={<CopyOutlined />}
        >
          Copy
        </Button>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Popconfirm
          title="This will delete this credential. You will then be able to create a new one here. "
          onConfirm={() => apmMonitor(confirm, 'deleteGeoCreds', 'buttonClick')}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button
            data-testid={'deleteBtn'}
            style={{ marginRight: 20 }}
            danger
            disabled={loadingDeleteCreds}
            icon={<DeleteOutlined />}
          >
            {loadingDeleteCreds ? 'Deleting...' : 'Delete'}
          </Button>
        </Popconfirm>
      </Row>
    </div>
  )
}

const GeoserverContainer = () => {
  const { data, isLoading } = useGetGeoCredentials()

  useEffect(() => {
    document.title = 'API Access - AURIN'
  }, [])

  const {
    mutateAsync: createGeoCred,
    isLoading: loadingCreateCreds,
  } = useCreateGeoCredentials()

  const createCreds = () => {
    return createGeoCred()
  }

  if (isLoading) {
    return <Spin />
  }

  return (
    <>
      <PageTitle title="API Access" />
      <Row
        style={{
          width: '100%',
          height: 'min-content',
          border: '1px solid #eee',
          padding: 50,
        }}
      >
        {data && (
          <Col
            flex={'200px'}
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <h5 style={{ fontSize: 16 }}>
              <CloudServerOutlined />
              <span style={{ marginLeft: 5 }}>Credentials</span>
              <PopoverC
                content={
                  <p style={{ width: 500 }}>
                    The AURIN Data Provider (ADP) facilitates programmatic
                    access to AURINs data catalogue, over Web Feature Services
                    (WFS). To connect to the ADP endpoint{' '}
                    <a
                      href={'https://adp.aurin.org.au/geoserver/wfs'}
                      target={'_blank'}
                    >
                      (https://adp.aurin.org.au/geoserver/wfs)
                    </a>
                    , users need to generate unique login credentials here
                  </p>
                }
              />
            </h5>
          </Col>
        )}

        {data ? (
          <Col flex={'auto'}>
            <DetailRenderer key_={'Username'} value={data.username} />
            <DetailRenderer
              key_={'Password'}
              value={<HiddenField data={data} />}
            />
          </Col>
        ) : (
          <div
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Empty description={null} />
            <Row>
              <p style={{ fontSize: 18, marginTop: 20 }}>
                No Data Provider credentials found. Please click on the button
                below to generate
              </p>
            </Row>
            <Row>
              <Button
                data-testid={'generateCredentialBtn'}
                type={'primary'}
                onClick={() =>
                  apmMonitor(
                    createCreds,
                    eventName.createGeoCreds,
                    eventType.buttonClick
                  )
                }
                disabled={loadingCreateCreds}
              >
                {loadingCreateCreds ? 'Generating...' : 'Generate '} New
                Credential
              </Button>
            </Row>
          </div>
        )}
      </Row>
    </>
  )
}

export default GeoserverContainer

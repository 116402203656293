import { Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIsTouAccepted } from '@hooks/query/useLicences'
import { LicenceRender } from '@containers/userManagement/agreements/details'

const TocModal = () => {
  const {
    data: dataTou,
    isLoading: loadingTou,
    isFetched: fetchedTou,
  } = useIsTouAccepted()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(!dataTou)
  }, [dataTou])

  if (loadingTou) {
    return <Spin size={'large'} />
  }

  if (fetchedTou && !dataTou) {
    return (
      <Modal
        wrapClassName={'touModal'}
        title="AURIN Services Terms of Use"
        visible={visible}
        onOk={() => setVisible(true)}
        okText="Accept"
        cancelText="Reject"
        footer={null}
        closable={false}
        width={800}
        style={{ top: 0, zIndex: 999 }}
      >
        <LicenceRender
          pdfurl="https://resources.aurin.org.au/legal/TOU.pdf"
          acceptedLicense={false}
          licenceCode={'tou'}
          setVisible={setVisible}
        />
      </Modal>
    )
  }

  return null
}
export default TocModal

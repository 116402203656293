export const AURIN_COLORS = {
  orange: '#f5822b',
  orangePaler: '#f5caaa',
  gold: '#e1b82e',
  goldPaler: '#f9f0db',
  green: '#91ae3b',
  greenPaler: '#eaedda',
  cobalt: '#a6c5d1',
  cobaltPaler: '#dfebea',
  blue: '#4682b4',
}

import { Table, TableProps } from 'antd'
import React from 'react'

const TableC = (
  // eslint-disable-next-line
  props: TableProps<any> & {
    handleSearch?: (value: string) => void
    handleUserIsActive?: (status: boolean) => void
    counts?: {
      items?: number
      total?: number
    }
  }
) => {
  const Footer = () => {
    if (props.dataSource?.length && props.dataSource?.length > 10) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexDirection: 'row-reverse',
          }}
        >
          <span style={{ fontStyle: 'italic' }}>
            Total items - {props.dataSource?.length}
          </span>
        </div>
      )
    }
  }

  return (
    <Table
      {...props}
      style={{ minWidth: '100%' }}
      rowKey={props.rowKey || 'id'}
      footer={Footer}
      pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
    />
  )
}

export default TableC

import { Button, Checkbox, message, Modal, Row, Typography } from 'antd'
import React, { useState } from 'react'
import {
  useDeleteUserSelector,
  useGetSectors,
  useGetUserSectors,
  usePostUserSelector,
} from '@hooks/query/useSector'

const SectorModal = ({
  showButton = false,
  buttonText = 'Update',
  isInitVisible = false,
}) => {
  const [visible, setVisible] = useState(isInitVisible || false)
  const { data: dataSectors, isLoading: loadingSectors } = useGetSectors()

  const {
    data: dataUserSectors,
    isLoading: loadingUserSectors,
  } = useGetUserSectors()

  const [isChecked, setIsChecked] = useState(
    !!dataUserSectors?.find((item) => item.code === 'aca')
  )

  const {
    mutateAsync: postUserSector,
    isLoading: loadingSaveSector,
  } = usePostUserSelector()

  const { isLoading: loadingDeleteSector } = useDeleteUserSelector()

  const handleAcceptAcaSector = async () => {
    try {
      await postUserSector('aca')
      setVisible(false)
    } catch (error) {
      if (error instanceof Object) {
        if ('statusText' in error && typeof error.statusText == 'string') {
          message.error(error.statusText)
        }
      }
    }
  }

  if (loadingSectors || loadingUserSectors || !dataSectors) {
    return null
  }

  return (
    <div>
      <Modal
        title="Academic Sector Confirmation"
        visible={visible}
        onCancel={() => setVisible(false)}
        okText="Accept"
        cancelText="Reject"
        footer={null}
        width={700}
        destroyOnClose
        zIndex={100}
      >
        <Row
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography.Text>
            We've identified that you have an academic email address ("edu.au").
            Users with an academic email address are eligible to access data
            which is limited to the academic sector. If you intend to use the
            AURIN Data Provider for academic or research purposes, please check
            the box below
          </Typography.Text>
          <Checkbox
            data-testid="academicSectorConfirmCheckBox"
            style={{ marginTop: 20 }}
            onChange={(e) => setIsChecked(e.target.checked)}
            checked={isChecked}
          >
            <Typography.Text style={{ fontWeight: 'bold' }}>
              Use for academic or research purposes
            </Typography.Text>
          </Checkbox>
        </Row>

        <Row
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: 40,
          }}
        >
          {isChecked && (
            <Button
              data-testid="academicSectorConfirmBtn"
              type={'primary'}
              onClick={handleAcceptAcaSector}
              disabled={loadingSaveSector || loadingDeleteSector}
            >
              Submit
            </Button>
          )}
        </Row>
      </Modal>
      {showButton && (
        <Button type={'primary'} onClick={() => setVisible(true)}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
export default SectorModal

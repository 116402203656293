import EP from '@src/api/endpoints'
import client from '@src/api'

export const getLicences = (params) => {
  return client
    .get(EP.LICENCES(params))
    .then((res) => (res.data?.items ? res.data?.items : res.data))
}

export const getUserLicences = () => {
  return client.get(EP.USER_LICENCES).then((res) => res.data)
}

export const getUserLicence = (code) => {
  return client.get(EP.USER_LICENCE(code)).then((res) => res.data)
}

export const postUserLicence = (body) => {
  return client.post(EP.USER_LICENCES, body).then((res) => res.data)
}

export const deleteAUserLicence = (licenceId) => {
  return client.delete(EP.LICENCE_ID(licenceId)).then((res) => res.data)
}

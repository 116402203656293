import React, { useState } from 'react'
import { Button, Col, Drawer, Row } from 'antd'
import { Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { pdfjs } from 'react-pdf'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FundProjectionScreenOutlined,
} from '@ant-design/icons'
import { useLicenceAdd } from '@hooks/query/useLicences'
import { ILicences } from '@/types/licences'
import { apmMonitor } from '@utils/apm'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const LicenceRender = ({
  licenceCode,
  acceptedLicense,
  setVisible,
  pdfurl,
}: {
  licenceCode: string
  acceptedLicense: boolean
  setVisible: (val: boolean) => void
  pdfurl: string
}) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const { mutateAsync: addALicence, isLoading: loadingAdd } = useLicenceAdd()

  const handleAddUserLicence = async () => {
    const body = {
      date_created: 0,
      code: licenceCode,
    }
    await addALicence(body)
    setVisible(false)
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <Row>
      <Col
        flex={'50px'}
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={pageNumber <= 1}
          onClick={previousPage}
          data-testid="previousPageBtn"
        >
          <ArrowLeftOutlined style={{ color: 'black' }} />
        </Button>
      </Col>
      <Col flex={'auto'}>
        <div
          style={{
            overflow: 'scroll',
            maxWidth: '700px',
            height: 'calc(100vh - 200px)',
            border: '1px solid #EEE',
          }}
        >
          <Document
            file={pdfurl}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkTarget={'_blank'}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>

        <div data-testid="pageNum" style={{ height: '60px', marginTop: 20 }}>
          <p style={{ textAlign: 'center' }}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            {pageNumber !== numPages && !acceptedLicense && (
              <span style={{ justifyContent: 'center' }}>
                <b> - Read all pages to agree terms and conditions</b>
              </span>
            )}
          </p>

          <div
            style={{
              position: 'relative',
              left: 0,
              bottom: 0,
            }}
          >
            <Row
              style={{
                justifyContent: 'space-between',
              }}
            >
              {pdfurl && (
                <p style={{ textAlign: 'center', marginTop: 10 }}>
                  <a href={pdfurl} target={'_blank'}>
                    Open PDF in a new tab
                  </a>
                </p>
              )}

              {!acceptedLicense && (
                <Button
                  data-testid="acceptLicenseBtn"
                  disabled={pageNumber !== numPages || loadingAdd}
                  type={'primary'}
                  onClick={() =>
                    apmMonitor(
                      handleAddUserLicence,
                      'acceptLicence',
                      'buttonClick',
                      {
                        licenceId: licenceCode,
                        liceceUrl: pdfurl,
                      }
                    )
                  }
                >
                  {loadingAdd ? 'Accepting...' : 'Accept'}
                </Button>
              )}
            </Row>
          </div>
        </div>
      </Col>
      <Col
        flex={'50px'}
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          data-testid="nextPageBtn"
        >
          <ArrowRightOutlined style={{ color: 'black' }} />
        </Button>
      </Col>
    </Row>
  )
}

const Details = ({
  acceptedLicense,
  data,
}: {
  data: ILicences
  acceptedLicense: boolean
}) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  return (
    <div>
      <Drawer
        title={data.name}
        placement="right"
        onClose={() => setVisible(false)}
        visible={visible}
        width={800}
      >
        <LicenceRender
          licenceCode={data.code}
          acceptedLicense={acceptedLicense}
          setVisible={setVisible}
          pdfurl={data.description}
        />
      </Drawer>

      <Button
        type={'primary'}
        onClick={() =>
          apmMonitor(showDrawer, 'readLicence', 'buttonClick', {
            licenceId: data.code,
            liceceUrl: data.description,
          })
        }
        icon={<FundProjectionScreenOutlined />}
      >
        Open
      </Button>
    </div>
  )
}

export default Details

import {
  deleteAUserLicence,
  getLicences,
  getUserLicence,
  getUserLicences,
  postUserLicence,
} from '@api/licences'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ILicences } from '@/types/licences'
import { message } from 'antd'

type IUserLicence = {
  Licence: ILicences
}

export default function useLicences(
  searchText?: string,
  userLicences?: ILicences[]
) {
  const qP = searchText ? `/search/${searchText}` : undefined
  return useQuery<ILicences[]>(
    ['licences', searchText],
    () => getLicences(qP),
    {
      select: (data) => {
        const licenceCodes = userLicences?.map((item) => item.code)
        return data.filter((item) => !licenceCodes?.includes(item.code))
      },
      enabled: !!userLicences,
    }
  )
}

export function useGetUserLicences() {
  return useQuery('userLicences', getUserLicences, {
    select: (res: IUserLicence[]) => res.map((item) => item.Licence),
  })
}

export function useGetUserLicence(code: string) {
  return useQuery(['userLicence', code], () => getUserLicence(code))
}

export function useIsTouAccepted() {
  return useQuery(['userLicence', 'tou'], () => getUserLicence('tou'), {
    select: (res: IUserLicence) => res?.Licence,
  })
}

export function useLicenceDelete() {
  const queryClient = useQueryClient()
  return useMutation(deleteAUserLicence, {
    onSuccess: (res) => {
      message.success(res?.message || 'Licence Successfully Revoked')
      return queryClient.invalidateQueries(['userLicences'])
    },
  })
}

export function useLicenceAdd() {
  const queryClient = useQueryClient()
  return useMutation(postUserLicence, {
    onSuccess: (res) => {
      message.success(res?.message || 'Licence Successfully Added')
      return queryClient.invalidateQueries(['userLicences'])
    },
  })
}

import axios from 'axios'
import { get } from 'lodash'
import { getToken } from '@utils/storage'
import { notification } from 'antd'
import React from 'react'

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function responseHandler(resp) {
  await timeout(1000)
  return {
    data: resp.data,
    message: get(resp, 'data.message', resp.statusText),
    status: get(resp, 'data.status', resp.status),
    success: true,
  }
}

async function errorHandler(e) {
  await timeout(1000)
  const respMessage = get(e, 'response.data')
  const statusCode = get(e, 'response.status')

  if (statusCode === 401) {
    notification.error({
      message: 'UnAuthorised Access',
      description: 'You will be logged out in 4 seconds',
      style: {
        width: 600,
      },
    })

    await new Promise((r) => setTimeout(r, 4000))
    window.location.reload(false)
  } else {
    notification.error({
      message: 'Error',
      description: <pre>{JSON.stringify(respMessage, null, 2)}</pre>,
      style: {
        width: 600,
      },
    })
  }

  throw e.response
}

function getAccessToken() {
  let accessToken = getToken()
  if (accessToken) {
    return 'Bearer ' + accessToken
  } else {
    return null
  }
}

const client = {
  async postUser(url, data, config = {}) {
    return axios
      .post(url, data, config)
      .then(responseHandler)
      .catch(errorHandler)
  },
  async get(url) {
    let accessToken = getAccessToken()
    axios.defaults.headers.common.Authorization = accessToken && accessToken
    return axios.get(url)
  },
  async post(url, data, config = {}) {
    let accessToken = getAccessToken()
    axios.defaults.headers.common.Authorization = accessToken && accessToken
    return axios
      .post(url, data, config)
      .then(responseHandler)
      .catch(errorHandler)
  },
  async patch(url, data, config = {}) {
    let accessToken = getAccessToken()
    axios.defaults.headers.common.Authorization = accessToken && accessToken
    return axios
      .patch(url, data, config)
      .then(responseHandler)
      .catch(errorHandler)
  },
  async put(url, data, config = {}) {
    let accessToken = getAccessToken()
    axios.defaults.headers.common.Authorization = accessToken && accessToken
    return axios
      .put(url, data, config)
      .then(responseHandler)
      .catch(errorHandler)
  },
  async delete(url, data, config = {}) {
    let accessToken = getAccessToken()
    axios.defaults.headers.common.Authorization = accessToken && accessToken
    return axios.delete(url, config).then(responseHandler).catch(errorHandler)
  },
}

export default client

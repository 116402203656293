import EP from '@src/api/endpoints'
import client from '@src/api'

export const createGeoServerCredentials = () => {
  return client
    .post(EP.GEO_SERVER_CREDENTIAL())
    .then((res) => res.data?.UserCredential || res.data)
}

export const getGeoServerCredentials = () => {
  return client
    .get(EP.GEO_SERVER_CREDENTIAL())
    .then((res) => res.data?.UserCredential)
    .catch(() => null)
}

export const deleteGeoServerCredentials = () => {
  return client.delete(EP.GEO_SERVER_CREDENTIAL()).then((res) => res.data)
}

export const stateBoundaryCoordinates = new Map([
  [
    'act',
    '148.761878 -35.287088,148.749390 -35.685170,148.932016 -35.928015,149.131122 -35.919119,149.418354 -35.307316,149.133911 -35.104251,148.761878\n' +
      '-35.287088',
  ],
  [
    'wa',
    '112.92 -13.68,112.92 -37.14,129.01 -37.14,129.01 -13.68,112.92 -13.68',
  ],
  ['nt', '129.00 -9.96,129.00 -26.00,138.01 -26.00,138.01 -9.96,129.00 -9.96'],
  [
    'sa',
    '129.00 -25.99,129.00 -38.07,141.01 -38.07,141.01 -25.99,129.00 -25.99',
  ],
  [
    'qld',
    '137.99 -9.14,137.99 -26.012824,140.993557 -26.012824,140.993557 -29.010617,149.006710 -29.041388,150.287991 -28.767659,151.386623 -29.309977,153.605862 -28.241942,153.56 -29.18,153.56 -9.14,137.99 -9.14',
  ],
  [
    'nsw',
    '140.99 -28.15,140.998535 -34.093255,144.738951 -36.315125,147.797270 -36.277493,148.012877 -36.848513,149.946470 -37.575467,159.11 -37.51,159.11 -28.15,140.99 -28.15',
  ],
  [
    'vic',
    '140.96 -33.98,140.96 -39.16,149.98 -39.16,149.98 -37.457418,147.827053 -35.849405,145.146389 -35.697107,144.706936 -35.671103,142.180080 -33.848035,140.96 -33.98',
  ],
  [
    'tas',
    '143.604999 -39.597223,145.700684 -43.764958,148.395038 -43.667872,148.963623 -39.620565,143.604999 -39.597223',
  ],
])

export const australianSpatialExtentReduced = [
  [111.19140625000001, -10.683350573778391],
  [111.19140625000001, -40.24344837865412],
  [154.88671875000003, -40.24344837865412],
  [154.88671875000003, -10.683350573778391],
  [111.19140625000001, -10.683350573778391],
]

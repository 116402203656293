import { init } from '@elastic/apm-rum'
import { getToken } from '../utils/storage'
import jwt_decode from 'jwt-decode'

export const apm = init({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'DASHBOARD_UI',
  transactionSampleRate: 1.0,
  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: window._env_.REACT_APP_APM_SERVER_URL,
  // Set the service version (required for source map feature)
  serviceVersion: '',
  // Set the service environment
  environment: window._env_.REACT_APP_APM_ENVIRONMENT,
})

// We can also add filters to ignore/modify the payload before it is sent to the APM Server
// For ex., don't send page load events
// apm.addFilter((payload) => console.log(payload))

export const apmMonitor = (action, eventName, eventType, eventLabels) => {
  const transaction = apm.startTransaction(eventName, eventType)

  let userObj

  try {
    const userJWT = getToken()
    userObj = jwt_decode(userJWT)
  } catch (error) {
    throw new error('Invalid JWT token')
  }

  // Set user id as user context to identify users across transactions
  userObj?.sub && apm.setUserContext({ id: userObj?.sub })
  if (eventLabels) transaction.addLabels(eventLabels)
  return Promise.resolve(action(transaction)).finally(() => transaction.end())
}

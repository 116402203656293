import Lottie from 'react-lottie'
import notFound from '@src/styles/lottie/notfound.json'

const RouteError = () => {
  return (
    <div
      style={{
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        minHeight: '100vh',
        marginTop: '100px',
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: notFound,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={400}
        width={400}
      />

      <div
        data-testid="show404"
        style={{
          textAlign: 'center',
          fontSize: '24px',
          width: '75%',
          margin: '0 auto',
        }}
      >
        Sorry we could not find that page!
      </div>
    </div>
  )
}

export default RouteError

import React, { ReactNode } from 'react'
import { Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { AURIN_COLORS } from '@constants/strings'

const PopoverC = ({ content }: { content: ReactNode | string }) => {
  return (
    <Popover content={content}>
      <span
        style={{
          marginLeft: 10,
          color: AURIN_COLORS.blue,
        }}
      >
        <InfoCircleOutlined />
      </span>
    </Popover>
  )
}

export default PopoverC

import React, { useContext } from 'react'
import { Col, Row, Tag } from 'antd'
import DetailRenderer from '@containers/userManagement/detailRenderer'
import SectorModal from '@containers/sector'
import { useGetUserSectors } from '@hooks/query/useSector'
import { AuthCtx } from '@contexts/auth.context'
import { UnorderedListOutlined } from '@ant-design/icons'
import PopoverC from '@components/common/popover'

const SectorContainer = () => {
  const { data } = useGetUserSectors()
  const { user } = useContext(AuthCtx)

  const Render = () => {
    return (
      <div>
        {data?.map((item) => (
          <Tag
            data-testid="academicSectorTag"
            key={item?.code}
            color={'green'}
            style={{ fontSize: 16, padding: 5 }}
          >
            {item?.name}
          </Tag>
        ))}
      </div>
    )
  }

  if (data && user?.email.endsWith('.edu.au')) {
    return (
      <div
        style={{
          flex: 1,
          width: '100%',
          height: 'min-content',
          border: '1px solid #eee',
          padding: 50,
          marginTop: 30,
        }}
      >
        <Row style={{ flex: 1, display: 'flex' }}>
          <Col
            flex={'200px'}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <h5 style={{ fontSize: 16 }}>
              <UnorderedListOutlined />
              <span style={{ paddingLeft: 5 }}>Sectors</span>
              <PopoverC
                content={
                  <p style={{ width: 500 }}>
                    Users with an academic email address are eligible to access
                    data which is limited to the academic sector. If you intend
                    to use the AURIN Data Provider for academic or research
                    purposes, you can confirm you are from the academic sector
                    here.
                  </p>
                }
              />
            </h5>
          </Col>

          {data.length === 0 && (
            <div>
              <SectorModal
                showButton={true}
                buttonText={'Academic Sector Confirmation'}
                isInitVisible={false}
              />
            </div>
          )}

          {data.length >= 1 && (
            <Col
              flex={'auto'}
              style={{
                flexDirection: 'row',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DetailRenderer border={false} value={<Render />} />

              {/*{data.length >= 1 && (*/}
              {/*  <div style={{ marginLeft: 10 }}>*/}
              {/*    <SectorModal showButton={true} buttonText={'Update'} />*/}
              {/*  </div>*/}
              {/*)}*/}
            </Col>
          )}
        </Row>
      </div>
    )
  }

  return null
}

export default SectorContainer

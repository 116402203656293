import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  deleteUserSector,
  getSectors,
  getUserSectors,
  postUserSector,
} from '@api/sectors'
import { message } from 'antd'

interface ISelector {
  code: string
  name: string
}

interface IUserSelector {
  Sector: ISelector
}

export function useGetSectors() {
  return useQuery<ISelector[]>('sectors', getSectors)
}

export function useGetUserSectors() {
  return useQuery('userSectors', getUserSectors, {
    select: (data: IUserSelector[] | []) => data.map((item) => item?.Sector),
  })
}

export function usePostUserSelector() {
  const queryClient = useQueryClient()
  return useMutation(postUserSector, {
    onSuccess: (res) => {
      message.success(res?.message || 'Sector set successfully')
      return queryClient.invalidateQueries(['userSectors'])
    },
  })
}

export function useDeleteUserSelector() {
  const queryClient = useQueryClient()
  return useMutation(deleteUserSector, {
    onSuccess: (res) => {
      message.success(res?.message || 'Sector deleted successfully')
      return queryClient.invalidateQueries(['userSectors'])
    },
  })
}

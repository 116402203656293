import logoImage from './aurin-logo-400.png'

function Logo({ aurinSite }: { aurinSite: string }) {
  return (
    <div
      style={{
        maxWidth: '160px',
      }}
    >
      <a href={aurinSite} target="_blank" rel="noopener noreferrer">
        <img src={logoImage} alt="logo" height="auto" width="100%" />
      </a>
    </div>
  )
}

export default Logo

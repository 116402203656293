import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import { Alert, Button, Form, Input, message, Row, Select, Spin } from 'antd'
import fileDownload from 'js-file-download'
import { sanitizeUrl } from '@braintree/sanitize-url'
import {
  useCreateGeoCredentials,
  useGetGeoCredentials,
} from '@hooks/query/useGeoServer'
import { SyncOutlined } from '@ant-design/icons'
import { IGeoCred } from '@/types/geoServer'
import { Buffer } from 'buffer'
import { useGetDataset } from '@hooks/query/useDatasets'
import {
  coversAustralianSpatialExtent,
  formatBytes,
  mapFileFormatsExtension,
} from '@utils/downloading'
import { stateBoundaryCoordinates } from '@constants/datasetFiltering'
import PageTitle from '@components/common/pageTitle'
import {
  Lottie404,
  showErrorNotification,
} from '@containers/dataset/errorHandling'

import { IForm, IOutputFormats } from '@/types/download'
import ReactMarkdown from 'react-markdown'
import { apmMonitor } from '@utils/apm'
import { eventName, eventType } from '@constants/eventTracking'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Dict } from 'styled-components/native/dist/types'
import PopoverC from '@components/common/popover'

const espgID = 4283
const wfsURLQueryString = '?request=GetFeature&typename='

const restrictedNames = [
  'apm_point_for_sale_qld',
  'apm_point_for_rent_nsw',
  'apm_point_for_sale_vic',
  'apm_point_for_sale_nsw',
  'apm_point_for_rent_vic',
  'apm_point_for_sale_wa',
  'apm_point_for_rent_qld',
  'apm-restricted-qld-apm-point-for-sale-qld-na',
  'apm-restricted-nsw-apm-point-for-rent-nsw-na',
  'apm-restricted-vic-apm-point-for-sale-vic-na',
  'apm-restricted-nsw-apm-point-for-sale-nsw-na',
  'apm-restricted-vic-apm-point-for-rent-vic-na',
  'apm-restricted-wa-apm-point-for-sale-wa-na',
  'apm-restricted-qld-apm-point-for-rent-qld-na',
]
const DownloadContainer = () => {
  const [dataUrl, setDataUrl] = useState('')
  const [finalDataUrl, setFinalDataUrl] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloaded, setDownloaded] = useState(0)
  const [isDownloadComplete, setIsDownloadComplete] = useState(false)
  const [form] = Form.useForm()

  const params = useParams()

  let datasetTitle = 'No Title'
  let isRestrictedDownload = false
  let datasetContent = 'No Description'
  let downloadUrl = window._env_.REACT_APP_GEOSERVER_WFS + wfsURLQueryString
  let geomField: string
  let adpID: string
  let spatialCoordinates: string
  const { data: data, isLoading: isLoading } = useGetDataset(params.datasetId)

  if (data && data['result']) {
    const datasetDetails = data['result']
    datasetTitle = datasetDetails['title']
    isRestrictedDownload = restrictedNames.includes(datasetDetails['name'])
    datasetContent = datasetDetails['notes']
    geomField = datasetDetails['extras']?.find(
      (element: Dict<string>) => element.key === 'Geometry Field'
    )?.value
    spatialCoordinates = datasetDetails['extras']?.find(
      (element: Dict<string>) => element.key === 'spatial'
    )?.value
    adpID = datasetDetails['extras']?.find(
      (element: Dict<string>) => element.key === 'ADP ID'
    )?.value
  }

  const [values, setValues] = useState({
    dataUrl: downloadUrl,
    filterState: 'none',
  })

  useEffect(() => {
    document.title = 'Download Data - AURIN'
    if (data && data['result']) {
      downloadUrl = downloadUrl + adpID
      setValues({
        dataUrl: downloadUrl,
        filterState: 'none',
      })
      setDataUrl(downloadUrl)
      setFinalDataUrl(downloadUrl)
    }
  }, [data])

  const { data: geoCreds } = useGetGeoCredentials()

  const {
    mutateAsync: createGeoCred,
    isLoading: isLoadingCreateCreds,
  } = useCreateGeoCredentials(false)

  useEffect(() => {
    setDataUrl(sanitizeUrl(downloadUrl))
  }, [])

  /*
   * Function to create/fetch return Data Provider Credentials.
   */
  const fetchGeoCreds = async (): Promise<IGeoCred> => {
    if (!geoCreds) {
      return createGeoCred()
    } else {
      return geoCreds
    }
  }

  const onDownload = async (values: IForm) => {
    const outputFormat: IOutputFormats = form.getFieldValue('outputFormat')
    const newDataUrl = new URL(dataUrl)
    newDataUrl.searchParams.set('outputFormat', outputFormat)
    const filterState = form.getFieldValue('filterState')

    setValues(values)

    // Strip outputFormat when the request format is gml
    if (!outputFormat || outputFormat === 'gml') {
      newDataUrl.searchParams.delete('outputFormat')
    }

    // Only filter if the filter is selected
    if (filterState != 'none' && filterState != undefined) {
      const filterCoordinates = stateBoundaryCoordinates.get(
        form.getFieldValue('filterState')
      )
      newDataUrl.searchParams.set('srsName', 'EPSG:' + espgID)
      newDataUrl.searchParams.set(
        'CQL_FILTER',
        getWithinQuery(filterCoordinates)
      )
    }

    try {
      setIsDownloading(true)
      setIsDownloadComplete(false)

      await fetchGeoCreds().then((res) => {
        return axios
          .get(sanitizeUrl(newDataUrl.toString()), {
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'Basic ' +
                Buffer.from(res?.username + ':' + res?.password).toString(
                  'base64'
                ),
            },
            onDownloadProgress: (progressEvent) => {
              setIsDownloading(true)
              setDownloaded(progressEvent.loaded)
            },
            responseType: 'blob',
          })
          .then((res) => {
            message.info('File downloaded success!')
            setIsDownloadComplete(true)
            apmMonitor(
              () => null,
              eventName.fileDownloaded,
              eventType.buttonClick,
              {
                downloadUrl: downloadUrl,
              }
            )
            // Set the filename using the dataset ID and state filter
            let fileName
            if (form.getFieldValue('filterState') == 'none') {
              fileName =
                params.datasetId +
                  mapFileFormatsExtension[values.outputFormat] || 'sample'
            } else {
              fileName =
                params.datasetId +
                  '(' +
                  form.getFieldValue('filterState') +
                  ')' +
                  mapFileFormatsExtension[values.outputFormat] || 'sample'
            }

            fileDownload(res.data, fileName)
            setIsDownloading(false)
          })
      })
    } catch (e: unknown) {
      setIsDownloading(false)
      if (e instanceof AxiosError) {
        e.response &&
          e.response.status &&
          showErrorNotification(e.response.status)
        apmMonitor(
          () => null,
          eventName.fileDownloadError,
          eventType.buttonClick,
          {
            downloadUrl: downloadUrl,
          }
        )
      }
    }
  }

  const getWithinQuery = (filterCoordinates: string | undefined) => {
    return `WITHIN(${geomField}, SRID=${espgID};polygon((${filterCoordinates})))`
  }
  const changeDownloadUrl = () => {
    if (form.getFieldValue('filterState') == 'none') {
      setFinalDataUrl(dataUrl)
    } else {
      const filterCoordinates = stateBoundaryCoordinates.get(
        form.getFieldValue('filterState')
      )
      const newDataUrl = `${dataUrl}&srsName=EPSG:${espgID}&CQL_FILTER=${getWithinQuery(
        filterCoordinates
      )}`
      setFinalDataUrl(newDataUrl)
    }
  }

  const canAllowToDownload = () => {
    if (
      spatialCoordinates &&
      coversAustralianSpatialExtent(spatialCoordinates) &&
      !geomField
    ) {
      return false
    }
    return true
  }
  const downloadAlert = () => {
    return (
      <div style={{ marginLeft: '30px' }}>
        <InfoCircleOutlined />
        &#160; This dataset is very large. You may experience issues trying to
        download this entire dataset via the browser. We have therefore limited
        the download type to CSV for this dataset. If you're still having issues
        accessing this data, get in touch at
        <a href="mailto:support@aurin.org.au"> support@aurin.org.au</a>
        <br></br>
        <br></br>
      </div>
    )
  }
  const downloadFormats = (isRestrictedDownload: boolean) => {
    if (isRestrictedDownload == true) {
      return (
        <Form.Item label="Format" name="outputFormat" initialValue="csv">
          <Select disabled={isDownloading} defaultActiveFirstOption={true}>
            <Select.Option value="csv">CSV</Select.Option>
          </Select>
        </Form.Item>
      )
    } else {
      return (
        <Form.Item
          label="Format"
          name="outputFormat"
          initialValue="application/json"
        >
          <Select disabled={isDownloading}>
            <Select.Option value="csv">CSV</Select.Option>
            <Select.Option value="gml">GML</Select.Option>
            <Select.Option value="application/json">GeoJSON</Select.Option>
            <Select.Option value="application/vnd.google-earth.kml+xml">
              KML
            </Select.Option>
          </Select>
        </Form.Item>
      )
    }
  }

  const Content = () => {
    return (
      <div>
        <div>
          <ReactMarkdown>{datasetContent}</ReactMarkdown>
        </div>
        <Form
          style={{ marginTop: 40 }}
          layout={'vertical'}
          form={form}
          initialValues={values}
          onFinish={onDownload}
        >
          <Form.Item label="WFS Data URL">
            <Input.TextArea
              disabled
              style={{ backgroundColor: 'white', color: 'black' }}
              value={finalDataUrl}
              data-testid={'wfsDataURL'}
            />
          </Form.Item>
          {isRestrictedDownload && downloadAlert()}
          {downloadFormats(isRestrictedDownload)}
          {!!spatialCoordinates &&
            !!geomField &&
            coversAustralianSpatialExtent(spatialCoordinates) && (
              <div>
                <Row>
                  <label>Spatial filter by state boundaries (Beta)</label>
                  <PopoverC
                    content={
                      <p style={{ width: 500 }}>
                        This is an experimental feature that uses a polygon
                        slightly larger than state boundaries to spatially
                        filter the data, and therefore may include features from
                        neighbouring states.
                      </p>
                    }
                  />
                </Row>
                <Row>
                  <Form.Item name={'filterState'} style={{ width: '100%' }}>
                    <Select
                      disabled={isDownloading}
                      onChange={changeDownloadUrl}
                    >
                      <Select.Option value="none">
                        None (download entire dataset)
                      </Select.Option>
                      <Select.Option value="act">
                        Australian Capital Territory
                      </Select.Option>
                      <Select.Option value="nsw">New South Wales</Select.Option>
                      <Select.Option value="nt">
                        Northern Territory
                      </Select.Option>
                      <Select.Option value="qld">Queensland</Select.Option>
                      <Select.Option value="sa">South Australia</Select.Option>
                      <Select.Option value="tas">Tasmania</Select.Option>
                      <Select.Option value="vic">Victoria</Select.Option>
                      <Select.Option value="wa">
                        Western Australia
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Row>
              </div>
            )}
          {!!adpID && canAllowToDownload() ? (
            <Form.Item shouldUpdate>
              {({ getFieldsError }) => {
                const error = getFieldsError().some(
                  ({ errors }) => errors.length
                )
                return (
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    disabled={isDownloading || error}
                    data-testid={'downloadBtn'}
                  >
                    {isDownloading ? 'Downloading ...' : 'Download'}
                  </Button>
                )
              }}
            </Form.Item>
          ) : (
            <div>
              This dataset is missing details required to enable a download.
            </div>
          )}
        </Form>
      </div>
    )
  }
  if (isLoading) {
    return <Spin />
  }
  if (!(data && data['result'])) {
    return <div>Unable to load dataset, invalid dataset ID.</div>
  }
  return (
    <div>
      <div
        style={{
          maxWidth: 700,
          minHeight: '100vh',
        }}
      >
        {data ? (
          <>
            <PageTitle title={datasetTitle} />
            <Content />
          </>
        ) : (
          <Lottie404 downloadUrl={downloadUrl} />
        )}

        {downloaded >= 1000 && !isDownloadComplete && (
          <Alert
            type="warning"
            showIcon
            icon={<SyncOutlined spin />}
            message={<div>Downloading - {formatBytes(downloaded)}</div>}
          />
        )}

        {isLoadingCreateCreds && (
          <Alert
            type="info"
            showIcon
            icon={<SyncOutlined spin />}
            message={<div>Generating Data Provider Credentials</div>}
          />
        )}

        {isDownloadComplete && (
          <Alert
            type="success"
            showIcon
            message={
              <div>
                Download Success! Total file size - {formatBytes(downloaded)}
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}

export default DownloadContainer

import React from 'react'
import './styles/App.less'
import { BrowserRouter } from 'react-router-dom'
import AppLayout from './layout'
import AuthProvider from '@contexts/auth.context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { message } from 'antd'
import { useLocation, useNavigate } from 'react-router'
import LoginRoute from '@src/router/loginRoute'
import MaintenancePage from './maintenance.tsx'

const AuthP = () => {
  const location = useLocation()
  const navigation = useNavigate()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: (err) => {
          try {
            if ([401, 403].includes(err.request.status)) {
              let referrerUri = `${encodeURIComponent(window.location.href)}`
              return navigation('/login?redirectUri=' + referrerUri, {
                replace: true,
              })
            }
            if (err.request.status === 500 || err.request.status === 0) {
              message.error('Error connecting server')
            }
          } catch (e) {
            console.error('Error ', e)
          }
        },
        cacheTime: 0,
        staleTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  if (
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/logout')
  ) {
    return <LoginRoute />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppLayout />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

function App() {
  if (window._env_.REACT_APP_UNDER_MAINTENANCE === 'true') {
    return <MaintenancePage />
  } else {
    return (
      <BrowserRouter>
        <AuthP />
      </BrowserRouter>
    )
  }
}

export default App

import { Image, Row } from 'antd'
import React from 'react'

const Footer = ({
  commitHash,
  versionNo,
}: {
  commitHash?: string
  versionNo?: string
}) => {
  return (
    <div style={{ backgroundColor: 'rgb(35, 88, 117)' }}>
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          display: 'flex',
          flex: 1,
          padding: '40px 20px',
        }}
      >
        <Image
          src={
            'https://aurin.org.au/wp-content/uploads/2018/11/aurin-and-partners-footer-logo-200-18.png'
          }
          preview={false}
          width={'300px'}
        />
        <div style={{ maxWidth: 700, textAlign: 'center' }}>
          <span style={{ color: 'rgb(143, 171, 185)', fontSize: 14 }}>
            Copyright 2022 © Australian Urban Research Infrastructure Network
            (AURIN) and The University of Melbourne. Funding for AURIN has been
            provided by the Australian Government under the National
            Collaborative Research Infrastructure Strategy (NCRIS) and
            associated programmes.
          </span>
        </div>
      </Row>
      {commitHash && (
        <p
          style={{
            textAlign: 'center',
            color: 'rgb(143, 171, 185)',
            fontSize: 12,
          }}
        >
          V{versionNo}({commitHash})
        </p>
      )}
    </div>
  )
}

export default Footer

import React, { useContext, useEffect } from 'react'
import { AuthCtx } from '@contexts/auth.context'
import { Col, Row } from 'antd'
import DetailRenderer from '@containers/userManagement/detailRenderer'
import Sector from '@containers/userManagement/account/sector'
import MarketingContainer from '@containers/userManagement/account/marketing'
import { UserOutlined } from '@ant-design/icons'
import PageTitle from '@components/common/pageTitle'

const AccountDetailsContainer = () => {
  const { user } = useContext(AuthCtx)

  useEffect(() => {
    document.title = 'My Account - AURIN'
  }, [])

  if (user) {
    return (
      <div style={{ flex: 1 }}>
        <PageTitle title="My Account" />
        <Row
          style={{
            width: '100%',
            height: 'min-content',
            border: '1px solid #eee',
            padding: 50,
          }}
        >
          <Col
            flex={'200px'}
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <h5 style={{ fontSize: 16 }}>
              <UserOutlined />
              <span style={{ paddingLeft: 5 }}> Account Details </span>
            </h5>
          </Col>
          <Col style={{}} flex={'auto'}>
            <DetailRenderer
              key_={'Name'}
              value={`${user.first_name} ${user.last_name}`}
            />
            <DetailRenderer key_={'Email'} value={user.email} />
          </Col>
        </Row>

        <Sector />
        <MarketingContainer />
      </div>
    )
  }

  return null
}

export default AccountDetailsContainer

import { Menu } from 'antd'

import {
  AppstoreOutlined,
  BarsOutlined,
  KeyOutlined,
  LockOutlined,
  QuestionOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ReactNode } from 'react'

const { SubMenu } = Menu
const submenuStyle = { fontSize: '14px' }

function NavigationBar({
  dataSearchSite,
  aurinSite,
  uiSite,
  buildLink,
}: {
  dataSearchSite: string
  aurinSite: string
  uiSite: string
  buildLink: (url: string, title: string) => ReactNode
}) {
  return (
    <div className="navigationBar">
      <Menu mode="horizontal" defaultSelectedKeys={['subm2']}>
        <SubMenu
          title="Manage access"
          key="subm2"
          icon={<KeyOutlined />}
          style={submenuStyle}
          data-testid="manageAccessSubMenu"
        >
          <Menu.Item icon={<UserOutlined key="acs1" />}>
            {buildLink(`${uiSite}/account/details/`, 'My Account')}
          </Menu.Item>
          <Menu.Item icon={<LockOutlined />} key="acs2">
            {buildLink(`${uiSite}/data-provider/`, 'API Access')}
          </Menu.Item>
          <Menu.Item icon={<BarsOutlined />} key="acs3">
            {buildLink(`${uiSite}/account/agreements/`, 'My Agreements')}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="subm1"
          title="Browse datasets"
          icon={<AppstoreOutlined />}
          style={submenuStyle}
          data-testid="browseDatasetsSubMenu"
        >
          <Menu.Item key="itm1">
            <a href={dataSearchSite + 'dataset'}>Browse all datasets</a>
          </Menu.Item>

          <Menu.Item key="itm2">
            <a href={dataSearchSite + 'organization'}>Browse by organisation</a>
          </Menu.Item>

          <Menu.Item key="itm3">
            <a href={dataSearchSite + 'group'}>Browse by access type</a>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          title="Help"
          key="subm3"
          icon={<QuestionOutlined />}
          style={submenuStyle}
          data-testid="helpSubMenu"
        >
          <Menu.Item key="hlp1">
            <a
              href={aurinSite + `/resources/training/ `}
              target="_blank"
              rel="noopener noreferrer"
            >
              Training & docs
            </a>
          </Menu.Item>
          <Menu.Item key="hlp2">
            <a
              href={aurinSite + `/contact-us/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  )
}

export default NavigationBar

import AurinLogo from './logo'
import NavigationBar from './navigationBar'
import SearchBar from './searchBar'
import './headerStyles.css'
import { Link } from 'react-router-dom'

function SharedHeader({
  dataSearchSite,
  aurinSite,
  uiSite,
}: {
  dataSearchSite: string
  aurinSite: string
  uiSite: string
}) {
  const sharedStyle = {
    width: '100%',
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
  }

  const buildLink = (path: string, title: string) => (
    <Link to={`${path}`}>{title}</Link>
  )

  return (
    <>
      <div className="headerLayout" style={sharedStyle}>
        <AurinLogo aurinSite={aurinSite} />

        <NavigationBar
          dataSearchSite={dataSearchSite}
          aurinSite={aurinSite}
          uiSite={uiSite}
          buildLink={buildLink}
        />

        <SearchBar dataSearchSite={dataSearchSite} />
      </div>
    </>
  )
}

export default SharedHeader

import { getUserDetails } from '@api/users.api'
import { useQuery } from 'react-query'
import { IUser } from '@/types/users'
import { AxiosResponse } from 'axios'

export default function useGetUserDetails(
  token: AxiosResponse<string> | undefined | null | string
) {
  return useQuery<IUser>(['userDetails', token], getUserDetails, {
    enabled: !!token,
  })
}

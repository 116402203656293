import React from 'react'
import { createRoot } from 'react-dom/client'
import './styles/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { apm } from '@utils/apm'

apm.setInitialPageLoadName('AURIN DASHBOARD')

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<App />)

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./App', render)
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { useContext } from 'react'
import AppRoutes from '@src/router'
import { Layout } from 'antd'
import { AuthCtx } from '@contexts/auth.context'
import LoginContainer from '@containers/login'
import SharedHeader from './components/sharedHeader'
import Footer from '@components/footer'
import TocModal from '@containers/toc'
import SectorModal from '@containers/sector'
import { useGetUserSectors } from '@hooks/query/useSector'
import packageJson from './../package.json'

function AppLayout() {
  const { token, error, user } = useContext(AuthCtx)
  const { data: dataUserSectors } = useGetUserSectors()

  if (!token || error) {
    return <LoginContainer />
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: 'white',
      }}
    >
      <TocModal />
      {user?.email.endsWith('.edu.au') &&
        dataUserSectors?.length === 0 &&
        user.first_login && <SectorModal isInitVisible />}
      <SharedHeader
        dataSearchSite={window._env_.REACT_APP_DATA_SEARCH_SITE}
        aurinSite={window._env_.REACT_APP_AURIN_SITE}
        uiSite={window._env_.REACT_APP_UI_HOST}
      />
      <Layout.Content
        style={{
          margin: 'auto',
          padding: '20px 20px 100px 20px',
          minHeight: '100vh',
          backgroundColor: 'white',
        }}
      >
        <AppRoutes />
      </Layout.Content>
      <Footer
        commitHash={process.env.REACT_APP_GIT_SHA}
        versionNo={packageJson.version}
      />
    </Layout>
  )
}

export default AppLayout

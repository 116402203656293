import React, { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginContainer from '@containers/login'
import UserManagementContainer from '@containers/userManagement'
import { AuthCtx } from '@contexts/auth.context'
import AccountDetailsContainer from '@containers/userManagement/account'
import LicenseContainer from '@containers/userManagement/agreements'
import GeoserverContainer from '@containers/userManagement/geoServer'
import DatasetDetailsContainer from '@containers/dataset'
import RouteError from '@containers/routeError'

function AppRoutes() {
  const { error } = useContext(AuthCtx)

  if (error) {
    return (
      <Routes>
        <Route path="/login" element={<LoginContainer />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<UserManagementContainer />} />
      <Route path="/account/details" element={<AccountDetailsContainer />} />
      <Route path="/data-provider" element={<GeoserverContainer />} />
      <Route path="/account/agreements" element={<LicenseContainer />} />
      <Route
        path={'/token'}
        element={<Navigate to={'/account/details'} replace />}
      />
      <Route path="/dataset/:datasetId" element={<DatasetDetailsContainer />} />
      <Route path="/*" element={<RouteError />} />
    </Routes>
  )
}

export default AppRoutes

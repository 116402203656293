function PageTitle({ title }: { title: string }) {
  return (
    <h1
      style={{
        fontFamily: 'Inter, Arial',
        textAlign: 'center',
        fontSize: '28px',
        padding: '15px 0 20px 0',
      }}
    >
      {title}
    </h1>
  )
}
export default PageTitle

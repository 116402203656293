import React from 'react'
import { Checkbox, Col, Row } from 'antd'
import DetailRenderer from '@containers/userManagement/detailRenderer'
import { useGetMarketing, usePatchMarketing } from '@hooks/query/useMarketing'
import { MailOutlined } from '@ant-design/icons'
import PopoverC from '@components/common/popover'

const MarketingContainer = () => {
  const {
    mutateAsync: updateOptOut,
    isLoading: loadingOptOut,
  } = usePatchMarketing()

  const {
    data: marketingOptOut,
    isLoading: marketingOptOutLoading,
  } = useGetMarketing()

  const Render = () => {
    return (
      <div>
        <Row>
          <Checkbox
            data-testid={'marketingOptChBx'}
            checked={marketingOptOut?.marketing_opt_out}
            onChange={(e) => {
              updateOptOut(e.target.checked)
            }}
            disabled={loadingOptOut || marketingOptOutLoading}
          >
            I do not wish to be contacted about upcoming AURIN news and events.
          </Checkbox>
        </Row>
      </div>
    )
  }

  return (
    <div
      style={{
        flex: 1,
        width: '100%',
        height: 'min-content',
        border: '1px solid #eee',
        padding: 50,
        marginTop: 30,
      }}
    >
      <Row style={{ display: 'flex' }}>
        <Col
          flex={'200px'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <h5 style={{ fontSize: 16 }}>
            <MailOutlined />
            <span style={{ paddingLeft: 10 }}>Marketing</span>
            <PopoverC
              content={
                <p style={{ width: 500 }}>
                  AURIN occasionally gets in contact with users of the AURIN
                  Data Provider (ADP) to get user feedback and promote things
                  like new datasets and upcoming training workshops. If you do
                  not wish to be contacted for this purpose you can choose to
                  opt-out here.
                </p>
              }
            />
          </h5>
        </Col>

        <Col style={{}} flex={'auto'}>
          <DetailRenderer border={false} value={<Render />} />
        </Col>
      </Row>
    </div>
  )
}

export default MarketingContainer

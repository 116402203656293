/* eslint-disable no-unused-vars */
export enum eventType {
  buttonClick = 'buttonClick',
  displayError = 'displayError',
  display404 = 'display404',
}
export enum eventName {
  jumpHome = 'jumpHome',
  pageNotFound = 'pageNotFound',
  fileDownloaded = 'fileDownloaded',
  fileDownloadError = 'fileDownloadError',
  readLicence = 'readLicence',
  acceptLicence = 'acceptLicence',
  createGeoCreds = 'createGeoCreds',
  copyGeoCreds = 'copyGeoCreds ',
  deleteGeoCreds = 'deleteGeoCreds',
}

import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import Details from '@containers/userManagement/agreements/details'
import { ColumnsType } from 'antd/es/table'
import useLicences, { useGetUserLicences } from '@hooks/query/useLicences'
import TableC from '@components/table'
import { ILicences } from '@/types/licences'
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import PopoverC from '@components/common/popover'
import PageTitle from '@components/common/pageTitle'

const LicenseContainer = () => {
  const [searchText, setSearchText] = useState<string>()
  const {
    data: userLicences,
    isLoading: loadingUserLicences,
  } = useGetUserLicences()

  useEffect(() => {
    document.title = 'My Agreements - AURIN'
  }, [])

  const { data: licences, isLoading: loadingLicences } = useLicences(
    searchText,
    userLicences
  )

  const columns: ColumnsType<ILicences> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '60%',
    },
    {
      title: 'Terms',
      dataIndex: 'pdf',
      render: (_, form) => {
        const hasUserAcceptedLicence = userLicences?.find(
          (item) => item.code === form.code
        )
        return (
          <Details acceptedLicense={!!hasUserAcceptedLicence} data={form} />
        )
      },
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <PageTitle title="My Agreements" />
      <Row
        style={{
          width: '100%',
          height: 'min-content',
          border: '1px solid #eee',
          padding: 50,
        }}
      >
        <Col flex={'auto'}>
          <h5
            style={{ fontSize: 18, textAlign: 'center', paddingBottom: '15px' }}
          >
            <CheckCircleOutlined />
            <span style={{ paddingLeft: 5 }}>Accepted Agreements</span>
            <PopoverC
              content={
                <p style={{ width: 500 }}>
                  Upon accepting the AURIN Terms of Use (ToU) you are granted
                  access to all Creative Commons and Open licenced data within
                  the AURIN catalogue through the Data Provider. To gain access
                  to additional datasets you need to open each of the relevant
                  agreements, read through and accept them for the associated
                  data to be accessible with your AURIN Data Provider
                  credentials
                </p>
              }
            />
          </h5>
          <TableC
            rowKey={'code'}
            dataSource={userLicences}
            columns={columns}
            size={'small'}
            loading={loadingUserLicences}
          />
        </Col>
        <Row
          style={{
            width: '100%',
            height: 'min-content',
            alignItems: 'center',
          }}
        >
          <p style={{ margin: '0 auto', color: 'gray', padding: '30px 0' }}>
            It may take a few minutes for changes to agreements to affect your
            downloads
          </p>
        </Row>
      </Row>

      <Row
        style={{
          width: '100%',
          height: 'min-content',
          border: '1px solid #eee',
          padding: 50,
          marginTop: 20,
        }}
      >
        <Col flex={'auto'}>
          <h5
            style={{ fontSize: 18, textAlign: 'center', paddingBottom: '15px' }}
          >
            <PlusCircleOutlined />
            <span style={{ paddingLeft: 5 }}>Available Agreements</span>
          </h5>
          <TableC
            rowKey={'code'}
            dataSource={licences}
            columns={columns}
            size={'small'}
            loading={loadingLicences}
            handleSearch={setSearchText}
          />
        </Col>
      </Row>
    </div>
  )
}

export default LicenseContainer

import React, { useContext } from 'react'
import { AuthCtx } from '@contexts/auth.context'
import AccountDetailsContainer from '@containers/userManagement/account'
import { Spin } from 'antd'

const UserManagementContainer = () => {
  const { user } = useContext(AuthCtx)

  if (user) {
    return (
      <div style={{ width: '100%' }}>
        <AccountDetailsContainer />
      </div>
    )
  }

  return <Spin />
}

export default UserManagementContainer

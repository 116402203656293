import { Image, Layout } from 'antd'
import Footer from '@components/footer'
import { ReactComponent as MaintenanceIcon } from './components/maintenance/maintenanceIcon.svg'
import packageJson from '../package.json'

const Header = Layout.Header

const MaintenancePage = () => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <div style={{ height: '100px' }}>
        <Header style={{ backgroundColor: 'white' }}>
          <Image
            src={
              'https://aurin.org.au/wp-content/uploads/2018/07/aurin-logo-400.png'
            }
            onClick={() =>
              (window.location.href = window._env_.REACT_APP_AURIN_SITE)
            }
            style={{ cursor: 'pointer' }}
            width={250}
            preview={false}
            alt={'AURIN logo'}
          />
        </Header>
      </div>

      <div
        style={{
          flexGrow: 3,
          alignSelf: 'center',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: 'auto',
          }}
        >
          <MaintenanceIcon
            style={{
              width: 400,
              height: 400,
              display: 'inline-block',
              margin: '0 auto',
            }}
          />
        </div>
        <p
          style={{
            textAlign: 'center',
            fontSize: '24px',
            width: '80%',
            margin: '0 auto',
          }}
        >
          Parts of the AURIN Data Provider are currently undergoing maintenance.
          We'll be back up and runnning shortly. If you need us you can get in
          contact{' '}
          <a href={'https://aurin.org.au/contact-us'} target={'_blank'}>
            here
          </a>
          .
        </p>
      </div>

      <div style={{ height: 120 }}>
        <Footer
          commitHash={process.env.REACT_APP_GIT_SHA}
          versionNo={packageJson.version}
        />
      </div>
    </div>
  )
}

export default MaintenancePage

import { Col, Row } from 'antd'
import React, { ReactNode } from 'react'

const DetailRenderer = ({
  key_,
  value,
  border = true,
}: {
  key_?: string
  value: string | string[] | ReactNode
  border?: boolean
}) => {
  const renderValue = () => {
    if (typeof value === 'string') {
      return <Col flex={'auto'}>{value}</Col>
    } else if (Array.isArray(value)) {
      return value.map((item) => (
        <Row key={item}>
          {item} <br />
        </Row>
      ))
    } else {
      return value
    }
  }

  return (
    <Row
      style={{
        borderBottom: border ? '1px solid #eee' : '',
        padding: '10px 10px',
        alignContent: 'center',
      }}
    >
      {key_ && (
        <Col flex={'150px'}>
          <span style={{ fontWeight: 'bold' }}>{key_}</span>
        </Col>
      )}
      <Col flex={'auto'}>{renderValue()}</Col>
    </Row>
  )
}

export default DetailRenderer

import EP from '@src/api/endpoints'
import client from '@src/api'

export const getUsers = (params) => {
  return client.get(EP.USERS(params)).then((res) => res.data)
}

export const getUser = (userId) => {
  return client.get(EP.USERS_ID(userId)).then((res) => res.data)
}

export const getUserDetails = () => {
  return client.get(EP.USER_DETAIL()).then((res) => res.data)
}

import { IOutputFormats } from '../types/download'
import Polygon from 'polygon'
import { australianSpatialExtentReduced } from '../constants/datasetFiltering'

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const mapFileFormatsExtension: Record<IOutputFormats, string> = {
  csv: '.csv',
  gml: '.xml',
  'application/json': '.json',
  'application/vnd.google-earth.kml+xml': '.kml',
}

export const coversAustralianSpatialExtent = (spatialCoordinates: string) => {
  if (spatialCoordinates.length > 0) {
    const datasetSpatialCoordsJSON = JSON.parse(spatialCoordinates)
    const datasetPolygon = new Polygon(datasetSpatialCoordsJSON.coordinates[0])

    if (
      datasetPolygon.containsPolygon(
        new Polygon(australianSpatialExtentReduced)
      )
    )
      return true
  }
  return false
}

import React, { useEffect, useState } from 'react'
import { getToken, storeToken } from '@utils/storage'
import { Spin } from 'antd'
import useGetUserDetails from '@hooks/query/useUser'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { IUser } from '@/types/users'
import HomeContainer from '@containers/home'

const REDIRECT_URL_REGEX = new RegExp(/onLoginSuccessRedirect=(.*)\?token/)
const TOKEN_REGEX = new RegExp(/\?token=(.*)/)

type IAuthContext = {
  token: string | null | undefined
  user: IUser | undefined
  setUser: (user: IUser) => void
  setToken: (token: string) => void
  error: boolean
  setError: (error: boolean) => void
}

const initialValue: IAuthContext = {
  token: null,
  user: undefined,
  setUser: () => null,
  setToken: () => null,
  error: false,
  setError: () => null,
}

export const AuthCtx = React.createContext<IAuthContext>(initialValue)

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<IAuthContext['token']>(getToken())
  const [user, setUser] = useState<IUser | undefined>()
  const [error, setError] = useState<IAuthContext['error']>(false)
  const { data: userData, isLoading } = useGetUserDetails(token)

  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const onLoginSuccessRedirect = params.get('onLoginSuccessRedirect')
  const paramsToken = params.get('token')

  /*
   * We use a different strategy to fetch user tokens on development machine.
   * auth service is deployed on a remote host which does not let cross domain
   * cookies to be set. As a result it is not possible to use cookie set by the
   * remote server to be used by localhost to fetch auth tokens
   */
  if (
    // window._env_.NODE_ENV === 'development' &&
    location.pathname !== '/token' &&
    !token
  ) {
    navigate('/login', { replace: true })
  }

  useEffect(() => {
    const windowHref = window.location.href
    const matchedRedirectUrl = REDIRECT_URL_REGEX.exec(windowHref)
    const matchedToken = TOKEN_REGEX.exec(windowHref)

    if (matchedRedirectUrl && matchedToken) {
      if (matchedToken) {
        storeToken(matchedToken[1])
        setToken(matchedToken[1])
      }
      if (matchedRedirectUrl) {
        window.location.href = matchedRedirectUrl[1]
      }
    }
  }, [onLoginSuccessRedirect])

  useEffect(() => {
    if (paramsToken) {
      storeToken(paramsToken)
      setToken(paramsToken)
    }
  }, [paramsToken])

  useEffect(() => {
    setUser(userData)
  }, [userData])

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          minHeight: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Spin size={'large'} />
      </div>
    )
  }

  if (!token) {
    return <HomeContainer />
  }

  return (
    <AuthCtx.Provider
      value={{ token, user, setUser, setToken, error, setError }}
    >
      {children}
    </AuthCtx.Provider>
  )
}

export default AuthProvider

import { Button } from 'antd'
import React from 'react'
import Footer from '@components/footer'
import Lottie from 'react-lottie'
import dataAnalyst from '@src/styles/lottie/data-analyst.json'
import { useLocation } from 'react-router'
import packageJson from '../../../package.json'
import SharedHeader from '../../components/sharedHeader'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: dataAnalyst,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const HomeContainer = () => {
  const location = useLocation()
  const getAuthUrl = () => {
    let baseUrl = `${window._env_.REACT_APP_AUTH_HOST}/uri-token/?redirectUri=${window._env_.REACT_APP_UI_HOST}/token`

    // Check if there's an existing redirect URI and extract the paramter for later redirection
    const queryString = location.search
    const urlParams = new URLSearchParams(queryString)
    let redirectUri = urlParams.get('redirectUri')

    // This should only be done if the page is not the login page
    if (redirectUri != null && !redirectUri.includes('/login')) {
      return `${baseUrl}?onLoginSuccessRedirect=${encodeURIComponent(
        redirectUri
      )}`
    }

    if (location.pathname === '/login') {
      return baseUrl
    }

    return `${baseUrl}?onLoginSuccessRedirect=${encodeURIComponent(
      window.location.href
    )}`
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <div style={{ height: '100px' }}>
        <SharedHeader
          dataSearchSite={window._env_.REACT_APP_DATA_SEARCH_SITE}
          aurinSite={window._env_.REACT_APP_AURIN_SITE}
          uiSite={window._env_.REACT_APP_UI_HOST}
        />
      </div>

      <div
        style={{
          flexGrow: 3,
          alignSelf: 'center',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Lottie options={defaultOptions} height={400} width={400} />
        <Button
          style={{}}
          type={'primary'}
          onClick={() => (window.location.href = getAuthUrl())}
        >
          Login
        </Button>
        <p style={{ padding: '10px', textAlign: 'center' }}>
          If you're having issues logging in, click{' '}
          <a href={'https://aurin.org.au/adpverification/'} target={'_blank'}>
            here
          </a>
        </p>
      </div>

      <div style={{ height: 120 }}>
        <Footer
          commitHash={process.env.REACT_APP_GIT_SHA}
          versionNo={packageJson.version}
        />
      </div>
    </div>
  )
}

export default HomeContainer

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getMarketingStatus, patchMarketing } from '@api/marketing'
import { notification } from 'antd'

export function useGetMarketing() {
  return useQuery('marketingOptOut', getMarketingStatus)
}

export function usePatchMarketing() {
  const queryClient = useQueryClient()
  return useMutation(patchMarketing, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries('marketingOptOut')
      if (res.marketing_opt_out) {
        notification.open({
          type: 'error',
          message: 'Opt out Successful',
          description: 'You can opt back in at anytime!',
        })
      } else {
        notification.open({
          type: 'success',
          message: 'Opt in Successful',
        })
      }
    },
  })
}

// eslint-disable-next-line no-undef
const API_BASE = window._env_.REACT_APP_API_HOST || 'http://127.0.0.1:8000/api'
const DASHBOARD_BASE = API_BASE + '/dashboard/v1'
const DATASET_URL = window._env_.REACT_APP_DATA_SEARCH_SITE
const DATASET_DETAILS_ROUTE = 'api/3/action/package_show?id='
const ENDPOINTS = {
  LOGIN: () => `${API_BASE}/auth/login`,
  USERS: (params) => `${DASHBOARD_BASE}/users/?${params}`,
  USERS_ID: (userId) => `${DASHBOARD_BASE}/users/${userId}`,
  USER_DETAIL: () => `${DASHBOARD_BASE}/user`,
  LICENCES: (params) => `${DASHBOARD_BASE}/agreements${params ? params : ''}`,
  USER_LICENCES: `${DASHBOARD_BASE}/user_agreements`,
  USER_LICENCE: (code) => `${DASHBOARD_BASE}/user_agreements/${code}`,
  LICENCE_ID: (licenceId) => `${ENDPOINTS.USER_LICENCES}/${licenceId}`,
  GEO_SERVER_CREDENTIAL: () => `${DASHBOARD_BASE}/user_credential`,
  SECTORS: `${DASHBOARD_BASE}/sectors`,
  USER_SECTORS: `${DASHBOARD_BASE}/user_sector`,
  SECTOR_ONE: (sector) => `${DASHBOARD_BASE}/user_sector/${sector}`,
  OPT_OUT: `${DASHBOARD_BASE}/user/opt_out`,
  DATASET: (datasetID) => `${DATASET_URL}${DATASET_DETAILS_ROUTE}${datasetID}`,
}

export default ENDPOINTS
